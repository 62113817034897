/*!
 * This is free and unencumbered software released into the public domain.
 *
 * Anyone is free to copy, modify, publish, use, compile, sell, or
 * distribute this software, either in source code form or as a compiled
 * binary, for any purpose, commercial or non-commercial, and by any
 * means.
 *
 * In jurisdictions that recognize copyright laws, the author or authors
 * of this software dedicate any and all copyright interest in the
 * software to the public domain. We make this dedication for the benefit
 * of the public at large and to the detriment of our heirs and
 * successors. We intend this dedication to be an overt act of
 * relinquishment in perpetuity of all present and future rights to this
 * software under copyright law.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND,
 * EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
 * MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT.
 * IN NO EVENT SHALL THE AUTHORS BE LIABLE FOR ANY CLAIM, DAMAGES OR
 * OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE,
 * ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR
 * OTHER DEALINGS IN THE SOFTWARE.
 *
 * For more information, please refer to <http://unlicense.org/>
 */

@charset "UTF-8";

html {
    color: #24292e;
    background-color: #f6f8fa;
}

html, textarea {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI" ,Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

a {
    color: #0366d6;
}

aside, footer {
    font-size: 9pt;
    color: #444;
}

code {
    background-color: #eee;
}
a > code {
    background-color: inherit;
}

.box label {
    display: block;
    font-weight: bold;
    margin-bottom: .5em;
}

.box textarea {
    display: block;
    box-sizing: border-box;
    width: 100%;
    margin: 0 auto;
    padding: 2px .25em;
    font-size: 24pt;
}

.box {
    margin: 1rem auto;
}

main, aside, footer {
    margin: 1rem;
}

summary {
    padding: 5px;
    border-radius: 4px;

    background-color: #f6f8fa;
    box-shadow: inset -1px -1px 2px rgba(0, 0, 0, 0.1),
                inset 1px 1px 2px rgba(255, 255, 255, 0.1);

    cursor: pointer;
}

.extra-controls {
    background-color: #d8d8e0;
    border-radius: 0 0 2px 2px;
    box-shadow: inset 1px 3px 1px rgba(0,0,0, 0.1);
    z-index: -5;
    padding: .75rem .5em;
}

.extra-controls fieldset {
    border: none;
    border-top: 2px solid rgba(100% 100% 100% / 25%);
}

.extra-controls fieldset:first-of-type {
  border: 0;
}

fieldset > label {
    display: inline-block;
}

main > header {
  margin-bottom: 1.5rem;
}

header > h1 {
    margin-bottom: 0;
}

h1 + [role=doc-subtitle] {
    margin-top: .25em;
}

/* Mobile-S size: max-width: 320px; */
/* Mobile-M size: */
@media (max-width: 375px) {
    h1 {
        font-size: 1.25rem;
    }

    .hide-phone {
        display: none;
    }

    .box textarea {
        min-height: 5.5ex;
    }

    /* HACK! Get rid of the bullet in the second line of <li>s on small screens */
    ul.meta.meta li:nth-child(2)::before {
        content: ' ';
    }
}

footer {
    text-align: center;
}

ul.meta {
    margin-left: 0;
    padding-left: 0;
    list-style: none;
}


ul.meta li {
    display: inline-block;
}

ul.meta li:not(:first-child)::before {
    content: " · ";
    margin: auto .25em;
}
